import {getApiURL} from "./tools";
import Swal from "sweetalert2";

export default class Favourites {
    sourceApp = null;
    loggedUser = false;
    csrfToken = null;

    constructor() {

        this.counter = 0;
        this.itemName = 'fitems';
        this.loggedUser = false;

        if (!window.localStorage) {
            console.warn('**** LS is unavailable ****');
            return false;
        }

        this.sourceApp = window.localStorage;
        this.data = JSON.parse(this.sourceApp.getItem(this.itemName) ?? '[]');

        window.addEventListener('DOMContentLoaded', () => {


            this.loggedUser = document.querySelector('meta[name="uid"]') ? document.querySelector('meta[name="uid"]').getAttribute('content') : false;
            if (!document.head.querySelector('meta[name="csrfToken"]')) {
                console.warn('**** No API URL found. ****');
                return false;
            } else {
                this.csrfToken = document.head.querySelector('meta[name="csrfToken"]').getAttribute('content');
            }

            if (document.head.querySelector('meta[name="newLogIn"]')) {
                this.loadFromDB();
            }

            window.addEventListener('storage', () => {
                console.log('---------- event STORAGE ----------');
                this.updateFromStorage();
            });

        });

        document.addEventListener('click', e => {
            if (!this.loggedUser) {
                return;
            }
            const source = e.target.classList.contains('btn-fav') ? e.target : e.target.closest('.btn-fav');
            if (source) {
                e.preventDefault();

                const offerDiv = source.closest('.offer');
                if (offerDiv && offerDiv.classList.contains('favourite-item')) {

                    Swal.fire({
                        text: 'Czy na pewno chcesz usunąć wybraną pozycję?',
                        icon: 'warning',
                        confirmButtonText: 'Tak, usuń.',
                        showCancelButton: true,
                        cancelButtonText: 'Anuluj',
                    }).then(result => {
                        if (result.isConfirmed) {
                            this.toggleData(source);
                            offerDiv.remove();
                        }
                    });

                } else {
                    this.toggleData(source);
                }
            }
        });
    };

    checkStatusById(id) {
        if (!this.loggedUser) {
            return false;
        }
        return this.data.indexOf(id.toString()) > -1;
    };

    toggleData(item) {
        const id = item.dataset.fid.toString();
        if (!id) {
            console.error('*** No fid exist ***');
            return false;
        }
        this.updateFavData(id, item.classList.toggle('--selected'));

    }

    updateFavData(val, status) {
        const newValue = val.toString();

        if (status && !this.data.indexOf(newValue) >= -1) {
            this.data.push(newValue);
        }
        if (!status) {
            this.data.splice(this.data.indexOf(newValue), 1);
        }
        this.syncWithDb();
        this.sourceApp.setItem(this.itemName, JSON.stringify(this.data));
    }

    getFavAmount() {
        return this.counter;
    }

    updateFromStorage() {
        if (!this.loggedUser) {
            return false;
        }

        try {
            const saData = this.sourceApp.getItem(this.itemName) ?? '[]';
            if (saData) {
                this.data = JSON.parse(saData);
            }

        } catch (error) {
            console.warn('*** incorrect data source for favourite items ***');
            console.error(error);
            this.data = [];
        } finally {
            this.counter = this.data.length;
            if (document.querySelectorAll('.btn-fav[data-type="toggler"]').length) {
                document.querySelectorAll('.btn-fav[data-type="toggler"]').forEach(el => {

                    if (this.data.indexOf(el.dataset.fid.toString()) > -1) {
                        !el.classList.contains('--selected') ? el.classList.add('--selected') : true;
                        if (el.querySelector('span') && el.dataset.captionYes) {
                            el.querySelector('span').innerText = el.dataset.captionYes;
                        }
                    } else {
                        if (el.querySelector('span') && el.dataset.captionNo) {
                            el.querySelector('span').innerText = el.dataset.captionNo;
                        }
                        el.classList.contains('--selected') ? el.classList.remove('--selected') : true;
                    }
                });
            }

        }
    }

    async loadFromDB() {
        console.log('loadFromDB', this.loggedUser, this.csrfToken);

        if (!this.loggedUser || !this.csrfToken) {
            return false;
        }
        const formData = new FormData();
        formData.append(`_csrfToken`, this.csrfToken);

        await fetch(getApiURL('likeslist'), {
            method: 'GET', headers: {
                'Accept': 'application/json',
            },
        }).then(response => response.json())
            .then((data) => {
                console.log(data);
                this.sourceApp.setItem(this.itemName, JSON.stringify(data.data.map(x => x.toString())));
            }).catch(error => {
                console.error('error in favourites load', error);
            });
    }

    async syncWithDb() {

        if (!this.loggedUser || !this.csrfToken) {
            return false;
        }

        const formData = new FormData();
        formData.append('likes', this.data);
        formData.append(`_csrfToken`, this.csrfToken);

        await fetch(getApiURL('likes'), {
            method: 'POST', headers: {
                'Accept': 'application/json',
            }, body: formData,
        }).then((response) => {
            console.log(response.json());
        });
    }

}
