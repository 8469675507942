import axios from "axios"; //, {isCancel, AxiosError}
import Mustache from "mustache";
import {animate} from "./tools";
import Jstemplates from "./jstemplates";
import autoComplete from "@tarekraafat/autocomplete.js";

class FilterCheckboxElement {
    constructor(nameElement) {
        const nameElementArr = nameElement.match(/([a-z]*)\[(\d+)]/);
        if (nameElementArr.length !== 3) {
            return false;
        }
        this.filterCategory = nameElementArr[1];
        this.filterId = parseInt(nameElementArr[2]);
    }

    getFilterGroup() {
        return this.filterCategory;
    }

    getFilterId() {
        return this.filterId;
    }
}

class FiltersManagment {
    constructor() {

        const url = new URL(window.location);

        this.filterObject = {
            searchPhrase: '', industries: [], employmenttypes: [], salaries: [], locations: [],
        };

        if (url.searchParams.size) {
            for (let x in this.filterObject) {
                if (url.searchParams.get(x)) {
                    if ('string' === typeof this.filterObject[x]) {
                        this.filterObject[x] = new String(url.searchParams.get(x));
                        console.log(this.filterObject[x]);

                        document.querySelector('[name="searchPhrase"]').value = url.searchParams.get(x).replace(/[~`!@#$%^&*(){} \[\];:"'<,.>?\/\\|_+=-]/g, '');

                    } else if ('object' === typeof this.filterObject[x]) {

                        this.filterObject[x] = new String(url.searchParams.get(x)).split(',').map(v => parseInt(v));
                        this.filterObject[x].forEach(id => {
                            document.querySelector('.form-check-input[name="' + x + '[' + id + ']"]').checked = true
                        });
                    }
                }
            }
        }
        console.log('xxxx', this.filterObject);
    }

    updateUrl() {
        const url = new URL(window.location);
        let smthAdded = false;

        console.log('----------updateUrl-----------');
        console.log(this.filterObject);

        for (let x in this.filterObject) {
            if (this.filterObject.hasOwnProperty(x)) {
                if (!this.filterObject[x].length && url.searchParams.has(x)) {
                    url.searchParams.delete(x);
                    smthAdded = true;
                } else {

                    if ('string' === typeof this.filterObject[x]) {
                        if (this.filterObject[x].length) {
                            url.searchParams.set(x, this.filterObject[x].trim());
                        } else {
                            url.searchParams.delete(x);
                        }
                        smthAdded = true;
                    } else if ('object' === typeof this.filterObject[x] && this.filterObject[x].length > 0) {
                        url.searchParams.set(x, this.filterObject[x]);
                        smthAdded = true;
                    }
                }
            }
        }

        if (smthAdded) {
            window.history.pushState({}, '', url);
        }
    }

    resetFilters(kind = null) {
        const selectedFilters = document.getElementById('selectedFiltersList');
        if (kind === null) {
            selectedFilters.querySelectorAll('button[data-filter-category]').forEach(button => button.click());
            // this.filterObject.searchPhrase = '';
            // this.filterObject.employmenttypes = [];
            // this.filterObject.salaries = [];
            // this.filterObject.industries = [];
            // this.filterObject.locations = [];
        } else {
            if (this.filterObject.hasOwnProperty(kind)) {
                selectedFilters.querySelectorAll('button[data-filter-category="' + kind + '"]').forEach(button => button.click());
                // if ('string' === typeof this.filterObject[x]) {
                //     this.filterObject[kind] = '';
                // } else {
                //     this.filterObject[kind] = [];
                // }
            }
        }
    }

    _setFilterObject(category, newValue) {
        if (!this.filterObject.hasOwnProperty(category)) {
            return false;
        }

        try {

            if ('searchPhrase' === category) {
                this.filterObject[category] = newValue;
            } else {
                let group = this.filterObject[category];
                if (group.indexOf(parseInt(newValue)) > -1) {
                    group.splice(group.indexOf(parseInt(newValue)), 1);
                } else {
                    group.push(parseInt(newValue));
                }
            }

            this.updateUrl();

            return true;

        } catch (err) {
            console.warn(err);
            return false;
        }

    }

    clickOnFilter(inputElement) {
        const fe = new FilterCheckboxElement(inputElement.getAttribute('name'));

        if (!fe || !(fe.getFilterGroup() in this.filterObject)) {
            console.warn('Filter checkbox is incorrect!');
            return false;
        }

        return this._setFilterObject(fe.getFilterGroup(), fe.getFilterId());
    }
}

class SearchFilter {
    signal;

    constructor(attr) {
        if (!document.querySelector(attr.fieldId)) {
            throw new Error('SearchField #{$attr.fieldId} is unavailable');
        }
        const searchFieldEl = document.querySelector(attr.fieldId);
        const controller = new AbortController()
        // const signal = controller.signal;

        this.filters = new FiltersManagment();

        const autoCompleteJS = new autoComplete({
            selector: attr.fieldId,
            placeHolder: searchFieldEl.hasAttribute('placeholder') ? searchFieldEl.getAttribute('placeholder') : 'Szukaj ...',
            data: {
                src: async (query) => {
                    try {
                        controller.current?.abort()
                        controller.current = new AbortController()
                        const source = await fetch(window._apiUrl + 'searchphrase.json?q=' + query.replace(/[~`!@#$%^&*(){}\[\];:"'<,.>?\/\\|_+=-]/g, ''), {
                            method: 'GET', signal: controller.current.signal
                        });
                        const data = await source.json();
                        return data.data.map(a => a.title);
                    } catch (error) {
                        return error;
                    }
                }, cache: false,
            },
            resultsList: {
                element: (list, data) => {
                    if (!data.results.length) {
                        // Create "No Results" message element
                        const message = document.createElement("div");
                        // Add class to the created element
                        message.setAttribute("class", "no_result");
                        // Add message text content
                        message.innerHTML = `<span>Nie znaleziono wyników dla "${data.query}"</span>`;
                        // Append message element to the results list
                        list.prepend(message);
                    }
                }, noResults: false, tabSelect: true,
            },
            resultItem: {
                highlight: true,
            },
            events: {
                input: {
                    selection(event) {
                        const feedback = event.detail;
                        autoCompleteJS.input.blur();
                        // Prepare User's Selected Value
                        // const selection = feedback.selection.value[feedback.selection.key];
                        // Render selected choice to selection div
                        // Replace Input value with the selected value
                        event.target.value = feedback.selection.value;
                        // Console log autoComplete data feedback
                        const ev = new KeyboardEvent('keyup', {
                            code: 'Enter', key: 'Enter', charCode: 13, keyCode: 13, view: window
                        });
                        event.target.dispatchEvent(ev);
                    },
                },
            }
        });
    }
}

export default class ApiV1Offers {
    constructor(wrapper) {
        if (!wrapper) {
            throw new Error('Element wrapper is empty!');
        }

        if (!window._apiUrl) {
            throw new Error('Api url is unknown!');
        }

        this.abortController = new AbortController();

        this.filters = new FiltersManagment();
        this.apiURL = window._apiUrl;
        this.wrapper = wrapper;
        this.jstemplates = new Jstemplates();
        this.searchFilter = new SearchFilter({fieldId: '#SearchFilter'});
    }

    start(favourtiesObject) {
        this._conectFilters();
        this.favourites = favourtiesObject;
    }

    _conectFilters() {
        if (document.querySelector('#SearchFilter')) {
            document.querySelector('#SearchFilter').addEventListener('keyup', e => {
                if ('Enter' === e.key) {
                    if (this.filters._setFilterObject('searchPhrase', e.target.value.replace(/[~`!@#$%^&*(){}\[\];:"'<,.>?\/\\|_+=-]/g, ''))) {
                        this.abortController.abort();
                        this.abortController = new AbortController();
                        this.fetchOffers();
                    }
                }
            });
        }
        this.wrapper.querySelectorAll('.offer-filters input.form-check-input').forEach(input => {
            input.addEventListener('change', e => {
                if (this.filters.clickOnFilter(e.target)) {
                    this.abortController.abort();
                    this.abortController = new AbortController();
                    this.fetchOffers();
                }
            });
        });

        this.fetchOffers();
    }

    renderEmptyNotice() {
        const contextThis = this;
        let contentData = document.createElement('div');
        contentData.classList.add('empty-offer-notice');
        contentData.setAttribute('aria-hidden', true);
        contentData.innerHTML = Mustache.render(this.jstemplates.getTemplate('offer_empty_notice'), {
            title: 'AKTUALNIE NIE MAMY OFERT PRACY DLA WYBRANYCH FILTRÓW',
            description: '<a class="reset-filter-sp" href="#">Zmień filtry i wyszukaj ponownie</a>'
        });

        document.getElementById('results').innerHTML = '';
        document.getElementById('results').appendChild(contentData);

        if (document.querySelector('.reset-filter-sp')) {
            document.querySelector('.reset-filter-sp').addEventListener('click', e => {
                e.preventDefault();
                if (contextThis.filters.filterObject.searchPhrase) {
                    contextThis.filters.resetFilters('searchPhrase');
                } else {
                    contextThis.filters.resetFilters();
                }

                contextThis.fetchOffers();
            });
        }

    }

    renderSkeleton(amount) {
        let contentData = document.createElement('div');

        for (let i = 0; i < amount; i++) {
            let item = document.createElement('div');
            item.classList.add('offer');
            item.setAttribute('aria-hidden', true);
            item.innerHTML = Mustache.render(this.jstemplates.getTemplate('offers_skeleton'), {});
            contentData.appendChild(item);
        }

        document.getElementById('results').innerHTML = '';
        document.getElementById('results').appendChild(contentData);
    }

    renderEmptySection(sectionType = 'big', message = 'Brak danych do pokazania', header = 'Uwaga') {
        const data = {
            section_tab: sectionType, header: header, message: message
        };

        return Mustache.render(this.jstemplates.getTemplate('empty_section'), data);
    }

    renderPil(category, id, caption) {
        const button = document.createElement('button');
        button.dataset.filterId = id;
        button.dataset.filterCategory = category;
        button.classList.add('pil-button');
        button.innerHTML = '<span>' + caption + '</span><i class="bi bi-x"></i>';
        button.addEventListener('click', e => {
            const el = e.currentTarget;
            if (el.dataset.filterId === 'null') {
                document.querySelector('input[name="searchPhrase"]').value = '';
                const ev = new KeyboardEvent('keyup', {
                    code: 'Enter', key: 'Enter', charCode: 13, keyCode: 13, view: window
                });
                document.querySelector('input[name="searchPhrase"]').dispatchEvent(ev);

            } else {
                document.querySelector('#' + el.dataset.filterCategory + '-' + el.dataset.filterId).click();
            }
        });
        return button;
    }

    renderSelectedFilters() {
        const wrapper = document.getElementById('selectedFiltersList');
        const filtersObj = this.filters.filterObject;
        // let result = '';

        if (!wrapper) {
            return false;
        }
        wrapper.innerHTML = '';

        for (let x in filtersObj) {

            if ('string' === typeof filtersObj[x]) {
                if (filtersObj[x].length > 0) {
                    wrapper.appendChild(this.renderPil(x, null, 'Fraza: ' + filtersObj[x]));
                }
            } else if ('object' === typeof filtersObj[x]) {
                for (let k in filtersObj[x]) {
                    try {
                        wrapper.appendChild(this.renderPil(x, filtersObj[x][k], document.querySelector('#' + x + '-' + filtersObj[x][k] + '+label').innerHTML));
                    } catch (error) {
                        console.error(error);
                    }
                }
            }
        }
    }

    renderOffer(offer) {
        let item = this.jstemplates.templateWrapperElShortOffer(offer.system_id, []);
        if (offer.is_new_offer) {
            offer.new_indicator = '<span class="badge badge-new --active">Nowe</span>';
        }

        offer.author_intro = '';

        if (offer.author_client && offer.author_company) {
            offer.author_intro = '<span>' + offer.author_company + '</span>' + ' dla ' + '<span>' + offer.author_client + '</span>';
        }

        item.innerHTML = Mustache.render(this.jstemplates.getTemplate('offers_short'), offer);
        if (this.favourites.checkStatusById(offer.system_id)) {
            item.querySelector('.btn-fav').classList.add('--selected');
        }

        return item;
    }

    renderOffers(list) {

        document.getElementById('results').innerHTML = '';
        const contentData = document.createElement('div');
        list.forEach((el) => {
            let item = this.renderOffer(el);
            contentData.appendChild(item);
        });

        document.getElementById('results').append(contentData);

    }

    setOfferCount(newValue) {
        const offerCountEl = document.getElementById('offersCount');
        offerCountEl.dataset.curval = parseInt(offerCountEl.innerText);
        offerCountEl.dataset.max = parseInt(newValue);
        animate(offerCountEl);
    }

    async fetchOffers() {
        let contextThis = this;
        this.renderSkeleton(5);
        this.renderSelectedFilters();
        await axios.get(this.apiURL + 'offers.json', {
            signal: this.abortController.signal, params: this.filters.filterObject
        }).then(function (response) {
            contextThis.renderOffers(response.data.offers);
            contextThis.setOfferCount(response.data.pagination.count);

            if (0 === response.data.pagination.count) {
                console.warn('Brak wyników');
                contextThis.renderEmptyNotice();
            }

        }).catch(function (thrown) {
            console.error(thrown);
        });
    }


}
