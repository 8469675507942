export default class OffersListActions {

    constructor() {
        this.checkElWrapper = document.querySelector('table.--row-list tbody');
        this.checkAllEl = document.querySelector('table.--row-list thead input.form-check-input');

        if (this.checkAllEl) {
            this.checkAllEl.addEventListener('click', this.checkAllAction);
        }
    }

    checkRowAction() {

    }

    checkAllAction(e) {
        console.log(e.target);
    }
}
