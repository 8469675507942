function getApiURL(action) {

    if (!action) {
        throw new Error('Api action not found');
    }

    let url = document.head.querySelector('meta[name="prefixUserApiURL"]').getAttribute('content');
    if (!url) {
        // throw new Error('Api url not found');
    }
    return url.replace('{fnc}', action);
}

function animate(counter) {
    if (!counter.dataset.numbers) {
        counter.dataset.numbers = '';
    }
    if (!counter.dataset.curval) {
        counter.dataset.curval = 0;
    }

    let currentValue = parseFloat(counter.dataset.curval);
    //}
    const maxValue = parseFloat(counter.dataset.max);
    const interations = (maxValue > 20) ? 20 : (maxValue > 10) ? 10 : parseInt(maxValue);

    let delta;

    if (parseFloat(maxValue) === parseInt(maxValue)) {
        delta = parseInt(maxValue / interations);
    } else {
        delta = Math.round(maxValue / interations * 100) / 100;
    }

    // console.log(maxValue, delta);

    if (currentValue < maxValue) {
        if (parseFloat(maxValue) === parseInt(maxValue)) {
            counter.innerText = Math.ceil(currentValue + delta);
        } else {
            counter.innerText = Math.round((currentValue + delta) * 10) / 10;
        }
        counter.dataset.curval = Math.ceil(currentValue + delta);
        counter.dataset.numbers += ',' + counter.dataset.curval;
        setTimeout(() => {
            animate(counter);
        }, 50);
    } else {
        if (parseFloat(maxValue) === parseInt(maxValue)) {
            counter.innerText = Math.ceil(maxValue);
        } else {
            // console.log(maxValue);
            counter.innerText = (Math.round(maxValue * 10) / 10);
        }
    }
}

const scrollDownButton = () => {
    if (document.querySelector('.scroll-down-icon')) {
        document.querySelector('.scroll-down-icon').addEventListener('click', () => {
            if (document.getElementById('nextSection')) {
                document.getElementById('nextSection').scrollIntoView({behavior: "smooth"});
            }
        });
    }
}

const filterObject = (obj, callback) => {
    return Object.fromEntries(Object.entries(obj).filter(([key, val]) => {
        const realValue = val === 1 && document.getElementById(key) && document.getElementById(key).dataset.profilePwr ? document.getElementById(key).dataset.profilePwr : val;
        console.log('********>>>', key, val, realValue);
        return callback(val, realValue);
    }));
}

const toCamelCase = str => str.trim().replace(/[-_\s]+(.)?/g, (_, c) => c ? c.toUpperCase() : '');
const getStyleObjectByElement = el => (el ? window.getComputedStyle(el) : false);

const serializeFromData = formDataEls => {
    const obj = {};
    // console.log('formDataEls', formDataEls.keys());
    for (let key of formDataEls.keys()) {
        // console.log('-->key', key, formDataEls.get(key), document.querySelector('[name="' + key + '"]').tagName.toLowerCase());
        if (key.endsWith('[]')) {
            obj[key] = formDataEls.getAll(key);
        } else {
            if ('textarea' === document.querySelector('[name="' + key + '"]').tagName.toLowerCase() && document.querySelector('[name="' + key + '"]').classList.contains('editor')) {
                // console.log('mamy pole', document.querySelector('[name="' + key + '"]'));
                // console.log('dane z WYSIWYG', document.querySelector('[name="' + key + '"]'), CKEDITOR.instances[document.querySelector('[name="' + key + '"]').getAttribute('id')].getData())
                obj[key] = CKEDITOR.instances[document.querySelector('[name="' + key + '"]').getAttribute('id')].getData();
            } else if (document.querySelector('[type="checkbox"][name="' + key + '"]')) {
                obj[key] = document.querySelector('[type="checkbox"][name="' + key + '"]').checked ? 1 : 0;
            } else {
                obj[key] = formDataEls.get(key);
            }
        }
    }
    return obj;
};

class AccordionOverride {
    constructor(el) {
        if (!el) {
            return false;
        }
        this._elWrapper = el;
        this._spyNav = document.getElementById('profile-right-nav');
        this._elWrapper.querySelectorAll('.accordion-button').forEach(button => {
            if (button.getAttribute('aria-expanded') && button.getAttribute('aria-expanded') === 'true') {
                button.removeAttribute('data-bs-target');
            }
            button.addEventListener('click', e => {
                e.preventDefault();
                e.stopPropagation();

                this.setOnSpyNav(e.target);

                e.target.removeAttribute('data-bs-target');

                if (e.target.getAttribute('aria-expanded') && e.target.getAttribute('aria-expanded') !== true) {
                    e.target.setAttribute('aria-expanded', true);
                }

                if (e.target.classList.contains('collapsed')) {
                    e.target.classList.remove('collapsed');
                }

                setTimeout(() => {
                    e.target.scrollIntoView({behavior: "smooth", block: 'start'})
                }, 400);

                this._elWrapper.querySelectorAll('.accordion-button:not([aria-controls="' + e.target.getAttribute('aria-controls') + '"])').forEach(elem => {//:not([data-bs-target])
                    elem.dataset.bsTarget = '#' + elem.getAttribute('aria-controls');
                    elem.setAttribute('aria-expanded', false);
                    elem.classList.add('collapsed');
                });
            });
        });
        if (this._spyNav) {
            this._spyNav.querySelectorAll('.nav-item').forEach(navLink => navLink.addEventListener('click', e => {
                e.preventDefault();
                const href = e.target.getAttribute('href');
                if (this._elWrapper.querySelector(href)) {
                    this._elWrapper.querySelector(href).querySelector('h3').dispatchEvent(new Event('click'));
                    setTimeout(() => {
                        this._elWrapper.querySelector(href).scrollIntoView({
                            behavior: "smooth", block: "start", inline: "nearest"
                        });
                    }, 350);
                }
            }));
        }
    }

    setOnSpyNav(el) {
        if (!el || !this._spyNav) {
            return false;
        }
        const connEl = el.getAttribute('aria-controls');

        if (!connEl) {
            return false;
        }

        if (this._spyNav.querySelector('[href="#' + connEl + 'Wrapper"]')) {
            this._spyNav.querySelectorAll('.nav-link.active').forEach(e => e.classList.remove('active'));
            this._spyNav.querySelector('[href="#' + connEl + 'Wrapper"]').classList.add('active');
        }

    }
}

export {
    animate,
    getApiURL,
    serializeFromData,
    scrollDownButton,
    filterObject,
    toCamelCase,
    getStyleObjectByElement,
    AccordionOverride
};
