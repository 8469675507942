window.addEventListener('DOMContentLoaded', () => {

    try {

        const offerHeader = document.querySelector('body.offer-details section.hero');
        const pageWrapper = document.querySelector('body>.page-wrapper');

        if (offerHeader && 'IntersectionObserver' in window) {

            let io = new IntersectionObserver((entries, observer) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        console.log('isIntersecting', entry.intersectionRatio);
                        if (offerHeader.classList.contains('fixed')) {
                            offerHeader.classList.remove('fixed');
                        }
                    } else {
                        console.log('!isIntersecting', entry.intersectionRatio);
                        offerHeader.classList.add('fixed')
                    }

                });
            }, {rootMargin: "-100px 0px -100px 0px"});//, threshold: .1

            io.observe(pageWrapper);
        }

    } catch (error) {
    }

    /**
     * Cały kafalek oferty powinien być klikalny
     */
    try {
        if (document.querySelector('.offers-interactions')) {
            document.querySelector('.offers-interactions').addEventListener('click', (e) => {
                const el = e.target;
                if (!el.closest('a')) {
                    e.preventDefault();
                    const offerWrapper = el.closest('.offer');
                    const childLink = offerWrapper.dataset.childLink;
                    if (offerWrapper && childLink && offerWrapper.querySelector(childLink)) {
                        window.location.href = offerWrapper.querySelector(childLink).getAttribute('href');
                    }
                }
            });
        }

    } catch (error) {
        console.warn('Offers list - error in outer click event');
    }
});
