export default class HyerAnalytics {
    constructor() {

    }

    startAutomateActions = () => {
        window.addEventListener('DOMContentLoaded', () => {
            const oid = document.head.querySelector('meta[name="oid"]') ? document.head.querySelector('meta[name="oid"]').getAttribute('content') : null;
            const aid = document.head.querySelector('meta[name="aid"]') ? document.head.querySelector('meta[name="aid"]').getAttribute('content') : null;
            const uid = document.head.querySelector('meta[name="uid"]') ? document.head.querySelector('meta[name="uid"]').getAttribute('content') : null;
            const cid = document.head.querySelector('meta[name="cid"]') ? document.head.querySelector('meta[name="cid"]').getAttribute('content') : null;

            if (document.querySelector('body.candidate-profile')) {
                this.updateProfileStats(true, cid, uid);
            }

            if (!oid) {
                return false;
            }

            if (document.querySelector('body.unlogged.offer-details')) {
                this.updateOfferStats(false, oid, aid);
            }

            if (document.querySelector('body.logged.offer-details')) {
                this.updateOfferStats(true, oid, aid);
            }

        });

    }

    updateOfferStats = (isLogged = false, offerId = null, authorId = null, additionalParams = {}) => {

        const url = window._apiUrl + 'analdata/offer';
        const csrfToken = document.head.querySelector('meta[name="csrfToken"]').getAttribute('content');

        const analData = {
            _csrfToken: csrfToken, idel: offerId, aid: authorId, ap: additionalParams
        };
        fetch(url, {
            method: 'POST', headers: {
                'X-CSRF-Token': csrfToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
            }, body: JSON.stringify(analData)
        });

        return true;
    }
    updateProfileStats = (isLogged = false, offerId = null, authorId = null, additionalParams = {}) => {

        const url = window._apiUrl + 'analdata/candidate';
        const csrfToken = document.head.querySelector('meta[name="csrfToken"]').getAttribute('content');

        const analData = {
            _csrfToken: csrfToken, idel: offerId, aid: authorId, ap: additionalParams
        };
        fetch(url, {
            method: 'POST', headers: {
                'X-CSRF-Token': csrfToken,
                'Accept': 'application/json',
                'Content-Type': 'application/json; charset=utf-8',
            }, body: JSON.stringify(analData)
        });

        return true;
    }
}
